import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { RiTwitterFill } from '@react-icons/all-files/ri/RiTwitterFill'

const Main = (props) => {
  const close = (
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  )

  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={props.timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <article
        id="aboutSite"
        className={`${props.article === 'aboutSite' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">About Site</h2>
        <span className="image main">
          <StaticImage
            formats={['auto', 'webp']}
            src="../../static/assets/pic02.jpg"
            alt="About Site"
          />
        </span>
        <h3>このWebサイトについて</h3>
        <p>
          このWebサイト（https://ridealize.co.jp　以下「当サイト」）は、R.IDEA.LIZE合同会社（以下「弊社」）が所有・運営しています。
          <br />
          当サイトのご利用にあたっては、以下の注意点をお読みいただき、ご了承の上でご利用ください。
        </p>
        <hr />

        <h3>免責条項</h3>
        <p>
          当サイトに掲載された情報に基づいてなされた判断を原因として発生したいかなるトラブル・損失・損害に対しても、弊社は責任を負いません。当サイトに掲載された全部又は一部の情報は、予告なく変更・削除されることがあります。また、当サイトの運営は予告なく中止することがあります。当サイトからの情報をご利用になったこと、またはご利用になれなかったことにより生じたいかなるトラブル・損失・損害についても責任を負いませんのでご了承ください。
        </p>

        <h3>当サイトへのリンクについて</h3>
        <p>
          当サイトへのリンク設定は、原則として自由です。ただし、以下に該当するWebサイトからのリンクはお断りします。
        </p>
        <ul>
          <li>
            弊社または役員・社員、及びサービス・製品を誹謗・中傷するWebサイトからのリンク
          </li>
          <li>違法である情報を含むWebサイトからのリンク</li>
          <li>公序良俗に反するWebサイトからのリンク</li>
          <li>
            インラインフレームに当サイトを取り込んだ形など、当サイトの情報であることが不明確となるWebサイトからのリンク
          </li>
        </ul>

        {close}
      </article>

      <article
        id="about"
        className={`${props.article === 'about' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">About</h2>
        <span className="image main">
          <StaticImage
            formats={['auto', 'webp']}
            src="../../static/assets/pic03.jpg"
            alt="About"
          />
        </span>
        <h3>R.IDEA.LIZE, LLC とは</h3>
        <p>
          「R.IDEA.LIZE(リデアライズ)」とは「IDEA{'<'}アイディア・イデア:理想型
          {'>'}」を私達独自の方法で「REALIZE{'<'}実現{'>'}
          」させるということを意味する造語です。私達は日々の生活の中で世界から数多くの恩恵を受けて生きています。その恩恵の中で培われたアイディアに新たな価値を付加し、世界へ還元したいと考えています。
        </p>
        <hr />
        <h3>社名</h3>
        <p>
          R.IDEA.LIZE, LLC
          <br />
          ※LLC（合同会社）とは、新会社法によって定められた新しい法人形態です。
        </p>
        <h3>設立</h3>
        <p>平成25年4月1日</p>
        <h3>代表社員</h3>
        <p>CEO（経営責任者） 伊藤　正人</p>
        <h3>事業内容</h3>
        <p>
          デジタルコンテンツの企画、製作
          <br />
          オリジナル商品の企画、立案
          <br />
          インターネットを利用したコンテンツの製作
        </p>
        <h3>E-mail</h3>
        <p>info@ridealize.co.jp</p>
        <h3>所在地</h3>
        <p>〒162-0053 東京都新宿区原町3-48-203</p>
        <StaticImage
          formats={['auto', 'webp']}
          src="../../static/assets/map.png"
          alt="Map"
        />
        {close}
      </article>

      <article
        id="contact"
        className={`${props.article === 'contact' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Contact Form</h2>
        <form
          className="contact-form"
          action="/thanks"
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="4"></textarea>
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" className="special" />
            </li>
            <li>
              <input type="reset" value="Reset" />
            </li>
          </ul>
        </form>
        <ul className="icons">
          <li>
            <Link
              to="https://twitter.com/ridealize"
              className="icon"
              rel="noopener noreferrer"
              target="_blank"
              area-label="Bibwoe"
            >
              <RiTwitterFill
                alt="Twitter"
                style={{
                  fontSize: '2rem',
                  color: 'gray',
                }}
              />
              <span className="label">Twitter</span>
            </Link>
          </li>
        </ul>
        {close}
      </article>
    </div>
  )
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
